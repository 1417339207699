<template>
  <div class="cmb-about">
    <div class="intro-model">
      <div class="title">金融评测系统 FINBenchmark</div>
      <div class="desc">
        Zhilu benchmark
        为中文金融领域的一项大模型能力评估基准，用于评估大语言模型的金融领域应用能力，包括金融知识储备和金融业务处理能力，评测数据由中山大学和招联金融人工智能研究中心共同开发。评测任务分为3个一级任务（金融知识、金融业务、金融智能体）和15个二级任务。您可以在github上下载我们的数据集用于评测。
      </div>
      <div class="d-flex img">
        <img class="cmb-img" src="@/assets/images/fin/fin.jpg" alt="" />
      </div>

      <div class="explain">
        <h2>Components</h2>
        <div class="block-list">
          <h3>金融知识</h3>
          <p class="list-desc">
            金融知识部分主要针对金融领域各个专业的知识考察，任务形式为选择题，涵盖以下类别：
          </p>
          <li><b>金融学：</b>考察金融市场、投资、公司理财等方面的知识。</li>
          <li><b>保险学：</b>涉及保险机制、保险产品和保险市场的知识。</li>
          <li><b>会计学：</b>会计原则、财务分析和报表理解。</li>
          <li><b>银行学：</b>银行业务、信贷政策、风险管理等。</li>
          <li>
            <b>从业资格：</b>覆盖金融行业从业人员需要的法律法规和职业道德。
          </li>

          <h3>金融业务</h3>
          <p class="list-desc">
            金融业务部分主要基于实际业务场景的任务设计，评估任务多样，包括：
          </p>
          <li><b>意图识别：</b>识别金融文本属于何种电销或催收意图。</li>
          <li><b>业务合规：</b>判断业务流程是否违反安全要求。</li>
          <li><b>情感迁移：</b>根据情感语气改写句子。</li>
          <li><b>对话摘要：</b>概括实际业务对话内容。</li>
          <li><b>业务问答：</b>总结已有信息回答业务问题。</li>
          <li><b>动作识别：</b>判断对话内容所属的动作类别。</li>

          <h3>金融智能体</h3>
          <p class="list-desc">
            金融智能体部分涉及更加复杂的任务，专注于文本处理和金融风险管理等方面：
          </p>
          <li><b>文段匹配：</b>评估模型对检索数据进行信息匹配的能力。</li>
          <li><b>风险评估：</b>评估模型根据用户信息评判潜在金融风险的能力。</li>
          <li>
            <b>工具调用：</b
            >评估模型的工具使用能力，包括规划、推理、检索、理解、指令跟随和审查。
          </li>
        </div>
      </div>
    </div>
    <img class="model-banner" src="@/assets/images/fin/fin2.jpg" alt="" />

    <!-- <div class="intro-model">
      <div class="title">数据</div>
      <div class="desc">
        我们的数据可以直接从<a
          class="ext-link"
          target="_blank"
          href="https://huggingface.co/datasets/FreedomIntelligence/CMB"
          >HuggingFace</a
        >下载。请参考我们的GitHub，了解如何访问和利用这些数据。
      </div>
    </div> -->
    <div class="intro-model">
      <div class="title">引用</div>
      <pre class="bibtax">
@misc{ceval,
      title={CEVAL: Comprehensive Evaluation Benchmark for Chinese Language Understanding},
      author={Yuzhen Huang, Yuzhuo Bai, Zhihao Zhu},
      year = {2023},
      publisher = {GitHub},
      journal = {GitHub repository},
      howpublished = {\url{https://github.com/hkust-nlp/ceval}},
}</pre
      >

      <pre class="bibtax">
@misc{Teval,
      title={T-Eval: Evaluating the Tool Utilization Capability of Large Language Models Step by Step},
      author={Zehui Chen, Weihua Du, Wenwei Zhang, Kuikun Liu, Jiangning Liu, Miao Zheng, Jingming Zhuo,Songyang Zhang, Dahua Lin, Kai Chen，Feng Zhao,},
      year = {2024},
      publisher = {GitHub},
      journal = {GitHub repository},
      howpublished = {\url{GitHub - open-compass/T-Eval: [ACL2024] T-Eval: Evaluating Tool Utilization Capability of Large Language Models Step by Step}},
}</pre
      >
    </div>
    <div class="intro-model">
      <div class="title">联系我们</div>
      <div class="desc">
        有关金融 benchmark 的问题，请通过691154380@qq.com联系我们。
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cmb-about {
  margin-top: 40px;

  .model-banner {
    height: 488px;
  }
  .intro-model {
    text-align: left;
    margin-bottom: 60px;
    .title {
      height: 44px;
      font-weight: 500;
      font-size: 36px;
      color: #1d2129;
      line-height: 44px;
      margin-bottom: 24px;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
      line-height: 24px;

      .ext-link {
        color: #165dff;
        text-decoration-line: underline;
      }
    }
    .img {
      justify-content: center;
    }

    .bibtax {
      padding: 16px;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      word-wrap: break-word;
      white-space: pre-wrap;
      margin-bottom: 24px;
    }
    .cmb-img {
      height: 723px;
    }
    .explain {
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
      .block-list {
        margin-top: 12px;
        font-weight: 400;
        font-size: 16px;
        color: #4e5969;
        line-height: 1.2;

      .list-desc{
        margin-bottom: 4px;
      }
        h3 {
          margin-top: 16px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>